import React from 'react';
import { motion } from "framer-motion";
import {useTranslation} from "react-i18next";


const CompanyHistory = () => {
  const { t } = useTranslation();
  const ItemVariants = {
    offscreen: {
      y: 20,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };


  return (
    <div className="intro4_Container">
      <div className="intro4_Container_subPage">
        <motion.div  initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={ItemVariants} className="intro4_title">
          {t('CompanyHistory.title')}
        </motion.div>
        <div className="intro4_history">

          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2023')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2023_10_2')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2023_10_1')}`}}/>
              </motion.ul>
            </dd>
          </dl>

          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2022')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2022_12')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2022_03')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2021')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2021_09')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2021_07')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2021_06')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2021_01')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2020')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2020_02')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2019')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2019_12')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2019_08')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2018')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2018_02')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2017')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2017_11')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2016')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2016_01')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2015')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2015_08')}`}}/>
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2015_06')}`}}/>
              </motion.ul>
            </dd>
          </dl>
          <dl className="intro4_history_dl">
            <dt>{t('CompanyHistory.2012')}</dt>
            <dd>
              <motion.ul
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={ItemVariants}
              >
                <li dangerouslySetInnerHTML={{__html:`${t('CompanyHistory.2012_07')}`}}/>
              </motion.ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default CompanyHistory;