import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from "swiper";
import {useTranslation} from "react-i18next";

const Intro1 = () => {
  const { t } = useTranslation();

  return (
    <div className="intro1 ">
      <div className="intro1_title"  id="category_intro1_title" >{t('intro1.title1')}<p>{t('intro1.title1-1')}</p></div>
      <div className="intro1_swipe">
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          navigation
          pagination={{clickable:true}}
          className={"intro1_swipe"}
          >
          <SwiperSlide className="intro1_slide">
                <div className="intro1_slide_container">
                  <div className="intro1_slide_title">
                    <span className="intro1_slide_subject">{t('intro1.slideSubject1')}</span>
                    <span className="intro1_slide_subject2">{t('intro1.slideSubject1-2')}<br/>{t('intro1.slideSubject1-3')}</span>
                    <span className="intro1_slide_tag">{t('intro1.slideTag1')}<br/>{t('intro1.slideTag1-1')}</span>
                  </div>
                  <div className="img_div">
                    <img alt="im" src="/assets/images/image_content1.png"/>
                  </div>
                </div>
            </SwiperSlide>
          <SwiperSlide className="intro1_slide">
            <div className="intro1_slide_container">
              <div className="intro1_slide_title">
                <span className="intro1_slide_subject">{t('intro1.slideSubject2')}</span>
                <span className="intro1_slide_subject2">{t('intro1.slideSubject2-1')}<br/>{t('intro1.slideSubject2-2')}</span>
                <span className="intro1_slide_tag">{t('intro1.slideTag2')}<br/>{t('intro1.slideTag2-1')}</span>
              </div>
              <div className="img_div">
                <img alt="im" src="/assets/images/image_content2.png"/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="intro1_slide">
            <div className="intro1_slide_container">
              <div className="intro1_slide_title">
                <span className="intro1_slide_subject">{t('intro1.slideSubject3')}</span>
                <span className="intro1_slide_subject2">{t('intro1.slideSubject3-1')}<br/>{t('intro1.slideSubject3-2')}</span>
                <span className="intro1_slide_tag">{t('intro1.slideTag3')}<br/>{t('intro1.slideTag3-1')}</span>
              </div>
              <div className="img_div">
                <img alt="im" src="/assets/images/image_content3.png"/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="intro1_slide">
            <div className="intro1_slide_container">
              <div className="intro1_slide_title">
                <span className="intro1_slide_subject">{t('intro1.slideSubject4')}</span>
                <span className="intro1_slide_subject2">{t('intro1.slideSubject4-1')}<br/>{t('intro1.slideSubject4-2')}</span>
                <span className="intro1_slide_tag">{t('intro1.slideTag4')}<br/>{t('intro1.slideTag4-1')}</span>
              </div>
              <div className="img_div">
                <img alt="im" src="/assets/images/image_content4.png"/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="intro1_slide">
            <div className="intro1_slide_container">
              <div className="intro1_slide_title">
                <span className="intro1_slide_subject">{t('intro1.slideSubject5')}</span>
                <span className="intro1_slide_subject2">{t('intro1.slideSubject5-1')}<br/>{t('intro1.slideSubject5-2')}</span>
                <span className="intro1_slide_tag">{t('intro1.slideTag5')}</span>
              </div>
              <div className="img_div">
                <img alt="im" src="/assets/images/image_content5.png"/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="intro1_slide">
            <div className="intro1_slide_container">
              <div className="intro1_slide_title">
                <span className="intro1_slide_subject">{t('intro1.slideSubject6')}</span>
                <span className="intro1_slide_subject2">{t('intro1.slideSubject6-1')}<br/>{t('intro1.slideSubject6-2')}</span>
                <span className="intro1_slide_tag">{t('intro1.slideTag6')}<br/>{t('intro1.slideTag6-1')}</span>
              </div>
              <div className="img_div">
                <img alt="im" src="/assets/images/image_content6.png"/>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

  )
}

export default Intro1