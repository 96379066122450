import React, {PropsWithChildren, useEffect, useState} from 'react';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import NewEvent from "../components/event/NewEvent";

const EventDetail = () => {
  const {t} = useTranslation()

  const params = useParams();
  const [eventDetail, setEventDetail] = useState({
    subject: "",
    sdate: "",
    edate: "",
    contents: "",
    option2: "",
    status: "",
  });
  const [loading, setLoading] = useState(true);


  const BlockWrapperWithMargin = ({ children }: PropsWithChildren<unknown>) => {
    return <div style={{ marginTop: '16px' }}>{children}</div>
  }

  const getEventDetail = async () => {

    try {
      const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/event/${params.eventId}`);
      if (status === 200) {
        setTimeout(() => {
          setLoading(false);
          setEventDetail(data);
        }, 400);
      }

    } catch (error: any) {
      setLoading(false);
      return error.response.data;

    }
  };


  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(()=> {
    getEventDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="eventDetail subPage">

        {
          loading ?
            <>
              <Skeleton width={"50%"} height={45} count={1} />
              <Skeleton wrapper={BlockWrapperWithMargin} width={"25%"} height={25} count={1} />
              <Skeleton wrapper={BlockWrapperWithMargin} width={"100%"} height={ 600} count={1} />
            </>
          : (<>
              <h1 className="detailSubject">{eventDetail.subject}</h1>
                {eventDetail.status === "always" ? "" :
                  <h2 className="detailDate">{eventDetail.sdate.slice(0, 10)} ~ {eventDetail.edate.slice(0, 10)}</h2>
                }

      <div>
        {<div className="detailDom" dangerouslySetInnerHTML={{__html:eventDetail.contents}} /> }
      </div>
      <div className="pageBack">
        <Link className="pageBackButton" to={'/events'}>{t('button.pageBack')}</Link>

      </div>
      {/*<NewEvent/>*/}
      </>
      )
  }
    </div>
  );
};

export default EventDetail;
