import Faq from "../components/faq/Faq";
import 'react-loading-skeleton/dist/skeleton.css';
import React from "react";
import {useTranslation} from "react-i18next";

const Help = () => {
  const {t} = useTranslation()
  return (
    <div className="faqPage">
      <div className="pageHeader">
        <h1>{t('navigation.faq')}</h1>
        {<p className="description" dangerouslySetInnerHTML={{__html:`${t('faqs.description')}`}} /> }
      </div>
      <Faq/>

    </div>
  )
}
export default Help;