import {Swiper, SwiperSlide} from "swiper/react";
import {useTranslation} from "react-i18next";

const Intro3 = () => {
  const { t } = useTranslation();
  return (
    <div className="intro3">
      <div className="intro3_title"><p>{t('intro3.title')}</p>{t('intro3.title2')}</div>
      <div className="intro3_container subPage">
        <div className="introContainer">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 16,
            }
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="intro3_section">
              <div className="intro3_section_title">
                <span>{t('intro3.sectionTitle1')}<br />{t('intro3.sectionTitle1-2')}</span>
                {/*<img alt="icon" src="/assets/images/Artboard_2.png"/>*/}
              </div>
              <div className="intro3_section_subtitle">{t('intro3.Subtitle1')}</div>
              <div className="intro3_section_subtitle button">{t('intro3.Button1')}</div>
            </div>
          </SwiperSlide>

          {/*<SwiperSlide>*/}
          {/*<div className="intro3_section">*/}
          {/*  <div className="intro3_section_title">*/}
          {/*    <span>{t('intro3.sectionTitle3')}<br/>{t('intro3.sectionTitle3-2')}</span>*/}
          {/*    /!*<img alt="icon" src="/assets/images/Artboard_2.png"/>*!/*/}
          {/*  </div>*/}
          {/*  <div className="intro3_section_subtitle">{t('intro3.Subtitle3')}</div>*/}
          {/*  <div className="intro3_section_subtitle button">{t('intro3.Button1')}</div>*/}
          {/*</div>*/}
          {/*</SwiperSlide>*/}

          <SwiperSlide>
            <div className="intro3_section">
              <div className="intro3_section_title">
                <span>{t('intro3.sectionTitle2')}<br/>{t('intro3.sectionTitle2-2')}</span>
              </div>
              <div className="intro3_section_subtitle">{t('intro3.Subtitle2')}</div>
              <div className="intro3_section_subtitle button">{t('intro3.Button4')}</div>
            </div>
          </SwiperSlide>

          {/*<SwiperSlide>*/}
          {/*<div className="intro3_section">*/}
          {/*  <div className="intro3_section_title">*/}
          {/*    <span>{t('intro3.sectionTitle4')}<br/>{t('intro3.sectionTitle4-2')}</span>*/}
          {/*  </div>*/}
          {/*  <div className="intro3_section_subtitle">{t('intro3.Subtitle4')}</div>*/}
          {/*  <div className="intro3_section_subtitle button">{t('intro3.Button4')}</div>*/}
          {/*</div>*/}
          {/*</SwiperSlide>*/}
          {/*<SwiperSlide>*/}
          {/*  <div className="intro3_section_none">*/}
          {/*    <div className="intro3_section_title">*/}
          {/*      <span><br/></span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</SwiperSlide>*/}

            {/*<SwiperSlide className="intro3_slide_swiper">*/}
            {/*<div className="intro3_section">*/}
            {/*  <div className="intro3_section_title">*/}
            {/*    <span>역직구 전자상거래 수출</span>*/}
            {/*    /!*<img alt="icon" src="/assets/images/Artboard_2.png"/>*!/*/}
            {/*  </div>*/}
            {/*  <div className="intro3_section_subtitle">해외 B2B 수출을 지원해요</div>*/}
            {/*  <div className="intro3_section_subtitle Readybutton">준비중</div>*/}
            {/*</div>*/}
            {/*</SwiperSlide>*/}

        </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Intro3