import {AccordionItemButton, AccordionItemHeading, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css'; // demo styles

const FaqItems = ({subject,contents} : any | null )  => {
    return (
        <>
            <AccordionItemHeading className="question">
                <AccordionItemButton className="text">
                    {subject}<span className="toggle"/>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="answer accordion__panel">
                {<div dangerouslySetInnerHTML={{__html:contents}} /> }
            </AccordionItemPanel>
        </>
    );
};

export default FaqItems;

