import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const Header404 = () => {
  const { t } = useTranslation();
  const [headerClassName, setHeaderClassName] = useState("");

  const handleScroll = (headerClassName: string) => {
    if (headerClassName !== 'menu-scroll' && window.scrollY >= 100) {
      setHeaderClassName('menu-scroll');
    } else if (headerClassName === 'menu-scroll' && window.scrollY < 100) {
      setHeaderClassName('');
    }
  }

  useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerClassName]);

  return (
    <div className={`headerWrap ${headerClassName}`}>
      <div className="headerContainer">
        <header className={`displayFlex justifyBetween darkTheme`}>
          <div className="displayFlex navContainer">
            <h1><NavLink to="/" className="ci-logo">{t('common.ci')}</NavLink></h1>
          </div>

          <nav className="linkNav">
            <a href="https://admin.sellerhub.co.kr/shop/admin_provider/login/login.php" id="button-login-pc" className="linkLogin">{t('outerLink.login')}</a>
            <a href="https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification.php" id="button-join-pc" className="shStart">{t('outerLink.shStart')}</a>
          </nav>
        </header>
      </div>
    </div>
  )
}
export default Header404;
