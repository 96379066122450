import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import snsYoutbe from '../assets/images/sns-youtube.svg';
import snsBlog from '../assets/images/sns-blog.svg';
import snsInsta from '../assets/images/sns-insta.svg';
import snsFb from '../assets/images/sns-facebook.svg';
import { useMediaQuery } from 'react-responsive'

const Footer = () => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState("off");

  const onToggle = () => {
    toggle === "off" ? setToggle("on") : setToggle("off")
    // console.log(toggle);
  }


  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });



  return (
    <div className='footerContainer subFooter'>
      <footer className="footer">
        <div className="footerFlex">
          <div className="footerInfo">
            <nav className="footerNav">
              <NavLink to="/service" className="nav">{t('navigation.service')}</NavLink>
              {/*<NavLink to="/about" className="nav">{t('navigation.about')}</NavLink>*/}
              {/*<NavLink to="/events" className="nav">{t('navigation.news')}</NavLink>*/}
              {/*<a href="https://career.sellerhub.co.kr/" target="_blank" rel="noreferrer" className="nav">{t('footer.career')}</a>*/}
            </nav>

            <nav className="footerNav">
              <NavLink to="/events" className="nav">{t('navigation.news')}</NavLink>
              <NavLink to="/helpcenter" className="nav">{t('navigation.help')}</NavLink>
              <NavLink to="/pricing" className="nav">{t('navigation.pricing')}</NavLink>
            </nav>
            <nav className="footerNav">
              <div className="career">
              <a href="https://career.sellerhub.co.kr/" target="_blank" rel="noreferrer" className="nav">{t('footer.career')}<span className="alwaysButton">상시채용</span></a>

                </div>
              {/*<NavLink to="/faq" className="nav">{t('navigation.help')}</NavLink>*/}
              {/*<NavLink to="/pricing" className="nav">{t('navigation.pricing')}</NavLink>*/}
            </nav>
          </div>

          <div className="footerInfo">
            {/*<div className="csInfo" dangerouslySetInnerHTML={{__html:`${t('footer.footerCs')}`}}></div>*/}
          </div>
        </div>

        <hr className="vertical"/>
        <div>
          <div className="footerInfo">
            <ul className="infoList">
              <li className="footer-ci"><span>{t('common.ci')}</span></li>
              <li>{t('footer.ceo')}</li>
              <li className={'aaa'}>
                <div className="ci-text">
                  {isMobile &&
                    <span className="borderRight" dangerouslySetInnerHTML={{__html:`${t('footer.address2')}`}}></span>
                  }
                  {!isMobile &&
                    <span className="borderRight">{isMobile? t('footer.address2') : t('footer.address')}</span>
                  }

                </div>
                <div className="ci-text">
                  <span className="borderRight">{t('footer.tel')}</span><span dangerouslySetInnerHTML={{__html:`${t('footer.supportEmail')}`}}></span>
                </div>
              </li>
              <li>
                <div className="ci-text">
                  <span className="borderRight">{t('footer.salesNumber')}</span><span className="borderRight"> {t('footer.bizNumber')}</span></div>
                <div className="ci-text">
                  <span><a href="mailto:cs@sellerhub.co.kr">{t('footer.persProtect')}</a></span></div>
              </li>
            </ul>


          </div>
          <div className="footerInfo snsList">
            <ul>
              <li className="liImg"><a href="https://www.youtube.com/channel/UCYZOm3vAvglrEUtovkToP0w" target="_blank" rel="noreferrer" className="snsIcon"><img src={snsYoutbe} alt="Sellerhub Youtube" /></a></li>
              <li className="liImg"><a href="https://blog.naver.com/sellerhub0219" target="_blank" rel="noreferrer" className="snsIcon"><img src={snsBlog} alt="Sellerhub Naver blog" /></a></li>
              <li className="liImg"><a href="https://www.instagram.com/accounts/login/?next=/sellerhub_official/" target="_blank" rel="noreferrer" className="snsIcon"><img src={snsInsta} alt="Sellerhub Instagram" /></a></li>
              <li className="liImg"><a href="https://www.facebook.com/sellerhubkorea" target="_blank" rel="noreferrer" className="snsIcon"><img src={snsFb} alt="Sellerhub Facebook" /></a></li>
            </ul>
            <div className="familySiteAlign">
              <ul className={`${toggle === 'off' ? 'familySiteList_off' : 'familySiteList_on'}`}>
                {!isMobile && <li><a href={"http://www.onch3.co.kr"} rel="noreferrer" target="_blank">온채널</a></li>}
                <li><a href={"http://www.lately.co.kr"}  rel="noreferrer" target="_blank">레이틀리</a></li>
                <li><a href={"https://www.sixshop.com/"}  rel="noreferrer" target="_blank">식스샵</a></li>
              </ul>
              <div className="FamilySiteButton_off" onClick={onToggle}>Family Site
                <span className={`${toggle === 'off' ? 'arrow_off' : 'arrow_on'}`} ><img src="/assets/images/Icon-down.svg" alt="" /></span>
              </div>
            </div>
          </div>
          <div className="footerCopy copyNSelect">
            <nav className="footerLink">
              <span className="borderRight">                
                <a href="https://www.pravs.co.kr/shop/admin_provider/mypage/mypage-terms01.php">{t('footer.agreement')}</a>
              </span>
              <a href="https://www.pravs.co.kr/shop/admin_provider/mypage/mypage-terms02.php">{t('footer.privacy')}</a>
              <div className="copyright">{t('common.copy')}</div>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default Footer;
