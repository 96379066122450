import React, {useRef, useState} from 'react';
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPlayer from 'react-player';
import {useTranslation} from "react-i18next";

const Tutorial = () => {

  const { t } = useTranslation();

  const videos:any = {
    tutorials: [
      {
        id :1,
        subject: '가입에서 계약',
        video: 'XtAkPz4zwOM',
        // tImg: 'https://img.youtube.com/vi/moYCX7qZL7U/sddefault.jpg',
        link: 'https://www.youtube.com/watch?v=XtAkPz4zwOM'
      },
      {
        id :2,
        subject: '플랜선택 및 기본정보 등록',
        video: 'lAZAd-HbHpQ',
        // tImg: 'https://img.youtube.com/vi/1myDAz_lPdc/sddefault.jpg',
        link: 'https://www.youtube.com/watch?v=lAZAd-HbHpQ'
      },
      {
        id :3,
        subject: '상품 등록',
        video: 'ivkg7GF1ec8',
        // tImg: 'https://img.youtube.com/vi/dt7prhlcY2U/sddefault.jpg',
        link: 'https://youtu.be/ivkg7GF1ec8',
      },
      // {
      //   id :4,
      //   subject: '상품 정보 수정',
      //   tImg: 'https://img.youtube.com/vi/L6pVUoZjafE/sddefault.jpg',
      //   video: '2UHjKIdxLsM',
      //   link: 'https://www.youtube.com/embed/L6pVUoZjafE?list=PLyww7R-xZN4IXgcQAnKTOdaSiP4JFMSLs'
      // },
      {
        //기존 - 주문건 처리
        id :4,
        subject: '주문건 처리',
        video: '0-s9ggElfds',
        // tImg: 'https://img.youtube.com/vi/0-s9ggElfds/sddefault.jpg',
        link: 'https://www.youtube.com/embed/0-s9ggElfds?list=PLyww7R-xZN4IXgcQAnKTOdaSiP4JFMSLs'
      },
      {
        id :5,
        subject: '정산',
        video: 'Wz4-P3KBGZ8',
        // tImg: 'https://img.youtube.com/vi/iplElxct9uU/sddefault.jpg',
        link: 'https://www.youtube.com/watch?v=Wz4-P3KBGZ8'
      },
    ]
  }

  let player = useRef<any>([]);
  const [playing1, setPlaying1] = useState<boolean>(false);
  const [playing2, setPlaying2] = useState<boolean>(false);
  const [playing3, setPlaying3] = useState<boolean>(false);
  const [playing4, setPlaying4] = useState<boolean>(false);
  const [playing5, setPlaying5] = useState<boolean>(false);
  // const [playing6, setPlaying6] = useState<boolean>(false);

  // const onSeek = (seconds: number) => {
  //   // @ts-ignore
  //   player.current.seekTo(seconds, "seconds");
  // };

  return (
    <div className="tutorialPage">
      <Helmet>
        <title>{t('pageTitle.tutorial')}</title>
      </Helmet>

      <div className="pageHeader">
        <h1>{t('navigation.tutorial')}</h1>
        <p className="description">{t('tutorial.description')}</p>
      </div>

      <div className="videoContainer subPage">
        <ul className="videoList">
          <li>
            <span className="number">{videos.tutorials[0].id}</span>
            <h2>{videos.tutorials[0].subject}</h2>
            <div className="seekToButton">
              <button onClick={() => {player.current[0].seekTo(5); setPlaying1(true)}}>{t('tutorial.video1-1')}</button>
              <button onClick={() => {player.current[0].seekTo(41); setPlaying1(true)}}>{t('tutorial.video1-2')}</button>
              {/*<button name="1" onClick={() => {player.current[0].seekTo(76); setPlaying1(true)}}>{t('tutorial.video1-3')}</button>*/}
              {/*<button onClick={() => {player.current[0].seekTo(92); setPlaying1(true)}}>{t('tutorial.video1-4')}</button>*/}
              {/*<button onClick={() => {player.current[0].seekTo(132); setPlaying1(true)}}>{t('tutorial.video1-5')}</button>*/}
            </div>
            <div className="videoBox">
              <ReactPlayer
                ref={el => (player.current[0] = el)}
                url={`https://youtu.be/${videos.tutorials[0].video}`}
                playing={playing1}
                controls
                muted={true}
                width={'100%'}
                height={'100%'}
                className={'reactPlayer'}
              />
              <Skeleton count={1} height="520px" borderRadius="24px" className="skeleton" />
            </div>
          </li>

          <li>
            <span className="number">{videos.tutorials[1].id}</span>
            <h2>{videos.tutorials[1].subject}</h2>
            <div className="seekToButton">
              <button onClick={() => {player.current[1].seekTo(5); setPlaying2(true)}}>{t('tutorial.video2-1')}</button>
              <button onClick={() => {player.current[1].seekTo(28); setPlaying2(true)}}>{t('tutorial.video2-2')}</button>
              <button onClick={() => {player.current[1].seekTo(58); setPlaying2(true)}}>{t('tutorial.video2-3')}</button>
              <button onClick={() => {player.current[1].seekTo(95); setPlaying2(true)}}>{t('tutorial.video2-4')}</button>
              <button onClick={() => {player.current[1].seekTo(145); setPlaying2(true)}}>{t('tutorial.video2-5')}</button>
              {/*<button onClick={() => {player.current[1].seekTo(115); setPlaying2(true)}}>{t('tutorial.video2-6')}</button>*/}
            </div>
            <div className="videoBox">
              <ReactPlayer
                ref={el => (player.current[1] = el)}
                url={`https://youtu.be/${videos.tutorials[1].video}`}
                playing={playing2}
                controls
                muted={true}
                width={'100%'}
                height={'520px'}
                className={'reactPlayer'}
              />
              <Skeleton count={1} height="520px" borderRadius="24px" className="skeleton" />
            </div>
          </li>

          <li>
            <span className="number">{videos.tutorials[2].id}</span>
            <h2>{videos.tutorials[2].subject}</h2>
            <div className="seekToButton">
              <button onClick={() => {player.current[2].seekTo(14); setPlaying3(true)}}>{t('tutorial.video3-1')}</button>
              <button onClick={() => {player.current[2].seekTo(31); setPlaying3(true)}}>{t('tutorial.video3-2')}</button>
              <button onClick={() => {player.current[2].seekTo(39); setPlaying3(true)}}>{t('tutorial.video3-3')}</button>
              <button onClick={() => {player.current[2].seekTo(66); setPlaying3(true)}}>{t('tutorial.video3-4')}</button>
              <button onClick={() => {player.current[2].seekTo(73); setPlaying3(true)}}>{t('tutorial.video3-5')}</button>
              <button onClick={() => {player.current[2].seekTo(89); setPlaying3(true)}}>{t('tutorial.video3-6')}</button>
              <button onClick={() => {player.current[2].seekTo(102); setPlaying3(true)}}>{t('tutorial.video3-7')}</button>
              <button onClick={() => {player.current[2].seekTo(116); setPlaying3(true)}}>{t('tutorial.video3-8')}</button>
              <button onClick={() => {player.current[2].seekTo(139); setPlaying3(true)}}>{t('tutorial.video3-9')}</button>

            </div>
            <div className="videoBox">
              <ReactPlayer
                ref={el => (player.current[2] = el)}
                url={`https://youtu.be/${videos.tutorials[2].video}`}
                playing={playing3}
                controls
                muted={true}
                width={'100%'}
                height={'100%'}
                className={'reactPlayer'}
              />
              <Skeleton count={1} height="520px" borderRadius="24px" className="skeleton" />
            </div>
          </li>

          <li>
            <span className="number">{videos.tutorials[3].id}</span>
            <h2>{videos.tutorials[3].subject}</h2>
            <div className="seekToButton">
              <button onClick={() => {player.current[3].seekTo(4); setPlaying4(true)}}>{t('tutorial.video4-1')}</button>
              <button onClick={() => {player.current[3].seekTo(87); setPlaying4(true)}}>{t('tutorial.video4-2')}</button>
              <button onClick={() => {player.current[3].seekTo(125); setPlaying4(true)}}>{t('tutorial.video4-3')}</button>
            </div>
            <div className="videoBox">
              <ReactPlayer
                ref={el => (player.current[3] = el)}
                url={`https://youtu.be/${videos.tutorials[3].video}`}
                playing={playing4}
                controls
                muted={true}
                width={'100%'}
                height={'520px'}
                className={'reactPlayer'}
              />
              <Skeleton count={1} height="520px" borderRadius="24px" className="skeleton" />
            </div>
          </li>

          <li>
            <span className="number">{videos.tutorials[4].id}</span>
            <h2>{videos.tutorials[4].subject}</h2>
            <div className="seekToButton">
              <button onClick={() => {player.current[4].seekTo(5); setPlaying5(true)}}>{t('tutorial.video5-1')}</button>
              <button onClick={() => {player.current[4].seekTo(57); setPlaying5(true)}}>{t('tutorial.video5-2')}</button>
              <button onClick={() => {player.current[4].seekTo(79); setPlaying5(true)}}>{t('tutorial.video5-3')}</button>
            </div>
            <div className="videoBox">
              <ReactPlayer
                ref={el => (player.current[4] = el)}
                url={`https://youtu.be/${videos.tutorials[4].video}`}
                playing={playing5}
                controls
                muted={true}
                width={'100%'}
                height={'520px'}
                className={'reactPlayer'}
              />
              <Skeleton count={1} height="520px" borderRadius="24px" className="skeleton" />
            </div>
          </li>

          {/*<li>*/}
          {/*  <span className="number">{videos.tutorials[5].id}</span>*/}
          {/*  <h2>{videos.tutorials[5].subject}</h2>*/}
          {/*  <div className="seekToButton">*/}
          {/*    <button onClick={() => {player.current[5].seekTo(8); setPlaying6(true)}}>{t('tutorial.video6-1')}</button>*/}
          {/*    <button name="1" onClick={() => {player.current[5].seekTo(23); setPlaying6(true)}}>{t('tutorial.video6-2')}</button>*/}
          {/*    <button onClick={() => {player.current[5].seekTo(39); setPlaying6(true)}}>{t('tutorial.video6-3')}</button>*/}
          {/*  </div>*/}
          {/*  <div className="videoBox">*/}
          {/*    <ReactPlayer*/}
          {/*      ref={el => (player.current[5] = el)}*/}
          {/*      url={`https://youtu.be/${videos.tutorials[5].video}`}*/}
          {/*      playing={playing6}*/}
          {/*      controls*/}
          {/*      muted={true}*/}
          {/*      width={'100%'}*/}
          {/*      height={'607px'}*/}
          {/*      className={'reactPlayer'}*/}
          {/*    />*/}
          {/*    <Skeleton count={1} height="607px" borderRadius="24px" className="skeleton" />*/}
          {/*  </div>*/}
          {/*</li>*/}
        </ul>
      </div>

    </div>
  )
}
export default Tutorial;