import React from 'react';
import { motion } from "framer-motion";
import {useTranslation} from "react-i18next";


const CompanyInovation = () => {
  const { t } = useTranslation();
  const ItemVariants = {
    offscreen: {
      y: 20,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  return (
    <div className="intro2_Container">
      <div className="intro2_Container_subPage">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          className="intro2_Content_Container">

          <motion.div className="intro2_Content_title" variants={ItemVariants}>
            <span className="intro2_Moto" dangerouslySetInnerHTML={{__html:`${t('CompanyInovation.moto')}`}}/>
            <span className="intro2_Submoto"  dangerouslySetInnerHTML={{__html:`${t('CompanyInovation.subMoto')}`}}/>
          </motion.div>
          <motion.div className="intro2_Link" variants={ItemVariants}>
            <a href="https://www.onch3.co.kr/">
              <div className="intro_Link_Card" id="button-link-onch3">

              <div className="intro_Link_Card_Flex">
                <span className="intro_Link_Card_Title">{t('CompanyInovation.onChannel')}</span>
                <span className="intro_Link_Card_subTitle"  dangerouslySetInnerHTML={{__html:`${t('CompanyInovation.onChannelTitle')}`}}/>
              {t('CompanyInovation.Go')} &gt;
              </div>

            </div>
            </a>
            {/*<a href="https://www.gemichango.com/">*/}
            {/*<div className="intro_Link_Card" id="button-link-gemichango">*/}
            {/*  <div className="intro_Link_Card_Flex">*/}
            {/*    <span className="intro_Link_Card_Title">{t('CompanyInovation.antGarage')}</span>*/}
            {/*    <span className="intro_Link_Card_subTitle"  dangerouslySetInnerHTML={{__html:`${t('CompanyInovation.antGarageTitle')}`}}/>*/}
            {/*    {t('CompanyInovation.Go')} &gt;*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*</a>*/}
          </motion.div>
        </motion.div>
        </div>
      </div>
  );
};

export default CompanyInovation;