import React, {useState, useEffect} from 'react';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPlayer from 'react-player';
import { Navigation, EffectFade } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-fade";
import 'swiper/css/navigation';
import { useTranslation } from "react-i18next";

const CustomerVideo = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [videos, setVideos] = useState<any[]>([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const getVideos = async () => {
    try {
      const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/video`);
      if (status === 200) {
        setLoading(false);
        setVideos(data);
      }
    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.response.data;
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getVideos();
  }, [])

  return (
    <div className="videosContainer">
      <h2 dangerouslySetInnerHTML={{__html:`${t('mainPage.customerHistory.title')}`}}></h2>
      <a
        href="https://www.youtube.com/channel/UCYZOm3vAvglrEUtovkToP0w/featured"
        target="_blank"
        rel="noreferrer"
        className="outLink">
        {t('mainPage.customerHistory.moreHistory')}
      </a>

      {loading && <Skeleton count={1} />}
      <div className="videosList">
        <Swiper
          modules={[Navigation, EffectFade]}
          effect={"fade"}
          slidesPerView={1}
          spaceBetween={0}
          className="mySwiper"
          navigation
        >
          {videos && videos.map((video: any) => (
            <SwiperSlide key={video.yCode}>
              <div className="videoItem">
                <div className="subject">
                  <h4>{video.subject}</h4>
                  <div className="htmlContents" dangerouslySetInnerHTML={{__html:video.contents}} />
                </div>
                <ReactPlayer
                  url={`https://youtu.be/${video.yCode}`}
                  playing={false}
                  controls
                  muted={true}
                  width={'600px'}
                  className="reactPlayer"
                />
              </div>
            </SwiperSlide>
          )).slice(0, 3)}
        </Swiper>
      </div>
    </div>
  );
};

export default CustomerVideo;
