import React from 'react';
import AppRoutes from './routes';
import ScrollToTop from "./scrollToTop";
const App = () => {
  return (
   <ScrollToTop>
     <AppRoutes />
   </ScrollToTop>
  );
};

export default App;