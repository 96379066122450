import React, {useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from './components';
import {useLocation} from 'react-router-dom';
import ChannelService from "./components/common/ChannelService";

const Layout = () => {
  const {pathname} = useLocation();
  function Channel() {
    ChannelService.boot({pluginKey: "3700bf60-cbd0-461f-ab2b-db5aff77d62f"})
  }

  useEffect(() => {
    return Channel();
  }, []);


  return (
    <div className="layoutContainer">
      <Header/>

      <main className={pathname === '/' ? 'mainPage' : ''}>

        <Outlet/>
        {/*<ChannelTalk/>*/}

      </main>
      {/*<button onClick={() => )}>123123</button>*/}
      <Footer/>
    </div>
  )
}
export default Layout;