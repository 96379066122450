import {useTranslation} from "react-i18next";

const Intro2 = () => {
  const { t } = useTranslation();
  return (
    <div className="intro2">
      <div className="intro2_title">{t('intro2.title')}<br/><p>{t('intro2.title2')}</p></div>
      <div className="intro2_container subPage" style={{ minHeight: "280px" }}>
          <div className="intro2_slide">
            <div className="intro2_section">
              <div className="intro2_section_title">
                <span>{t('intro2.sectionTitle1')}<br/>{t('intro2.sectionTitle1-2')}</span>
                <img alt="icon" src="/assets/images/icon_service1.png"/>
              </div>
              <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle')}</div>
            </div>
            <div className="intro2_section">
              <div className="intro2_section_title">
                <span>{t('intro2.sectionTitle2')}<br/>{t('intro2.sectionTitle2-2')}</span>
                <img alt="icon" src="/assets/images/icon_service2.png"/>
              </div>
              <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle2')}</div>
            </div>
            {/*<div className="intro2_section">*/}
            {/*  <div className="intro2_section_title">*/}
            {/*    <span>{t('intro2.sectionTitle3')}</span>*/}
            {/*    <img alt="icon" src="/assets/images/icon_service3.png"/>*/}
            {/*  </div>*/}
            {/*  <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle3')}</div>*/}
            {/*</div>*/}
            {/*<div className="intro2_section">*/}
            {/*  <div className="intro2_section_title">*/}
            {/*    <span>{t('intro2.sectionTitle4')}<br/>{t('intro2.sectionTitle4-2')}</span>*/}
            {/*    <img alt="icon" src="/assets/images/icon_service4.png"/>*/}
            {/*  </div>*/}
            {/*  <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle4')}</div>*/}
            {/*</div>*/}
            {/*<div className="intro2_section">*/}
            {/*  <div className="intro2_section_title">*/}
            {/*    <span>{t('intro2.sectionTitle5')}</span>*/}
            {/*    <img alt="icon" src="/assets/images/icon_service5.png"/>*/}
            {/*  </div>*/}
            {/*  <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle5')}</div>*/}
            {/*</div>*/}
            {/*<div className="intro2_section">*/}
            {/*  <div className="intro2_section_title">*/}
            {/*    <span>{t('intro2.sectionTitle6')}</span>*/}
            {/*    <img alt="icon" src="/assets/images/icon_service6.png"/>*/}
            {/*  </div>*/}
            {/*  <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle6')}</div>*/}
            {/*</div>*/}
            {/*<div className="intro2_section">*/}
            {/*  <div className="intro2_section_title">*/}
            {/*    <span>{t('intro2.sectionTitle7')}</span>*/}
            {/*    <img alt="icon" src="/assets/images/icon_service7.png"/>*/}
            {/*  </div>*/}
            {/*  <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle7')}</div>*/}
            {/*</div>*/}
            <div className="intro2_section">
              <div className="intro2_section_title">
                <span>{t('intro2.sectionTitle8')}</span>
                <img alt="icon" src="/assets/images/icon_service8.png"/>
              </div>
              <div className="intro2_section_subtitle">{t('intro2.sectionSubtitle8')}</div>
            </div>
          </div>
      </div>
    </div>

  )
}

export default Intro2