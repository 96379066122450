import axios from "axios";
import React, {useState, useEffect} from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useTranslation} from "react-i18next";
// import noImage from "../../assets/images/noneImage.png";

const Banner = () => {
  const { t } = useTranslation()
  const [bannerImage,setBannerImage] = useState<string>("")
  const [bannerTitle, setBannerTitle] = useState<string>("")
  const [bannerSno, setBannerSno] = useState<number>(0)

  const getBanner = async () => {
    try {
      const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/event?type=event&isPriority=Y`);
      if(status === 200) {
        //console.log("2: ", data);
        data.data === null ? setBannerImage("") : setBannerImage(data.data[0].tImg)
        setBannerTitle(data.data[0].subject)
        setBannerSno(data.data[0].provider_bbs_sno)
      }
    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.response.data;
    }
  }
  //
  // const handleImgError = (e: any) => {
  //   e.target.src = noImage;
  // };//no image


  useEffect(() => {
    getBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    return (
      <div className="bannerImage" style={{backgroundImage: bannerImage === "" ? "" : `url(${bannerImage})`}}>
        <div className="bannerContent">
          <div className="bannerText">
            <h2>{t('event.banner')}</h2>
            {bannerTitle === "" ? "" : <h1>{bannerTitle}</h1>}
            {bannerImage === "" ? "" : <a href={`/events/${bannerSno}`}><button className="banner_button">{t('event.detail')}</button></a> }
          </div>
          {!bannerImage
            ?
              <div className="banner">
                <Skeleton style={{borderRadius: 16}} width={360} height={360} count={1}/>
              </div>
            :
              <img className="banner" src={`${bannerImage}`} alt="banner"/>
          }

        </div>
        <div className="bannerBlur" >
        </div>
      </div>
    )
}

export default Banner