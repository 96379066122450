import 'react-loading-skeleton/dist/skeleton.css';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CsItem from "../components/cs/CsItem";

const CS = () => {


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchWord, setSearchWord] = useState("");
  // const [resultList, setResultList] = useState([]);
  useEffect(() => {
    if(searchWord){
      console.log(searchWord)
    }
  },[searchWord])

  const OnEnterPress = (e: { key: any, target: any; }) => {
    if( e.key === 'Enter') {
      window.open('https://sellerhub-co.gitbook.io/smb-support/?q='+e.target.value)
      console.log('Enter', e.target.value)
    }
  }

  const {t} = useTranslation()
  // @ts-ignore
  return (
    <div className="csPage">
      <div className="pageHeader">
        <h1>{t('navigation.cs')}</h1>
        <div  className="Search">
          <input className="SearchBox" placeholder="궁금한 내용의 키워드를 검색해 보세요" type={"text"} onKeyPress={OnEnterPress}/>
        </div>

        {/*{<p className="description" dangerouslySetInnerHTML={{__html:`${t('faqs.description')}`}} /> }*/}
        {/*{ resultList.length > 0 ?*/}
        {/*<div className="searchList">*/}
        {/*  <ul>*/}
        {/*    <li>{resultList[0] ? resultList[0] : ""}</li>*/}
        {/*  </ul>*/}
        {/*</div> : null*/}
        {/*}*/}
      </div>


    <CsItem/>

    </div>
  )
}
export default CS;