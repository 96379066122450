import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import csIcon from '../../assets/images/cs_icon/service_cs.svg'
// import csGroup from '../../assets/images/cs_icon/service_group.svg'
import csOrder from '../../assets/images/cs_icon/service_order.svg'
import csOrderCheck from '../../assets/images/cs_icon/service_ordercheck.svg'
import csPay from '../../assets/images/cs_icon/service_pay.svg'
import csPersonal from '../../assets/images/cs_icon/service_personal.svg'
import csPrice from '../../assets/images/cs_icon/service_price.svg'
import csProduct from '../../assets/images/cs_icon/service_product.svg'
import csProductAdd from '../../assets/images/cs_icon/service_productadd.svg'
import csQ from '../../assets/images/cs_icon/service_q.svg'
import csReady from '../../assets/images/cs_icon/service_ready.svg'
import csCalc from '../../assets/images/cs_icon/sevice_ calculate.svg'
// import underArrow from '../../assets/images/cs_icon/underArrow.svg'

const CsItem = () => {
  const { t } = useTranslation()

  const Item = [
    {
      icon: csReady,
      title: "판매준비",
      desc: "가입부터 판매정보 설정까지",
      url: "https://sellerhub-co.gitbook.io/smb-support/start/readyfor"
    },
    {
      icon: csProductAdd,
      title: "상품 등록",
      desc: "상품 등록부터 승인, 수정까지",
      url: "https://sellerhub-co.gitbook.io/smb-support/registration"
    },
    {
      icon: csProduct,
      title: "상품 관리",
      desc: "금칙어 및 상품 삭제",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/all/product/manage"
    },
    {
      icon: csOrder,
      title: "주문",
      desc: "주문확인과 패널티",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/all/order"
    },
    {
      icon: csOrderCheck,
      title: "주문처리",
      desc: "취소/반품/교환부터 송장 관리까지",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/all/order/manage"
    },
    {
      icon: csCalc,
      title: "정산",
      desc: "정산내역과 부가세 신고 자료",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/all/calculation"
    },
    {
      icon: csIcon,
      title: "CS",
      desc: "1:1문의와 긴급알리미",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/all/cs"
    },
    {
      icon: csPersonal,
      title: "계정정보",
      desc: "비밀번호 변경, 계약 정지/퇴점",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/account_settings/information"
    },
    {
      icon: csPrice,
      title: "이용요금",
      desc: "이용요금과 플랜별 연동 쇼핑몰",
      url: "https://sellerhub-co.gitbook.io/smb-support/pricing/information"
    },
    {
      icon: csPay,
      title: "결제수단 및 플랜",
      desc: "결제수단/플랜 등록 및 변경",
      url: "https://sellerhub-co.gitbook.io/smb-support/manager/account_settings/payment_plan"
    },
    {
      icon: csQ,
      title: "자주 묻는 질문",
      desc: "입점/운영/요금 관련",
      url: "https://sellerhub-co.gitbook.io/smb-support/faq"
    },
  ]


  return (
    <div className="csContents subPage">
      <Helmet>
        <title>{t('pageTitle.help')}{t('pageTitle.common')}</title>
      </Helmet>

      <div className="csContent">
        <div className="csItemWrap">
          {Item.map((items: any) => (
            <div className="csItem" onClick={() => {window.open(items.url)}}>
              <div className="ItemContainer">
                <img src={items.icon} alt={items.title}/>
                <div className="csItemEach">
                  <span className="csItemTitle">{items.title}</span>
                  <span className="csItemDesc">{items.desc}</span>
                </div>
              </div>
              {/*<div className="underArrow">*/}
              {/*  <img src={underArrow} alt={"underArrow"}/>*/}
              {/*</div>*/}
            </div>
          ))}
          <div className="csItem"><div className="noItemContainer"></div></div>
        </div>
      </div>
    </div>

  );
};

export default CsItem;
