import React, {useRef} from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
// import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import {HashLink} from "react-router-hash-link";

const Pricing = () => {
  const swiperRef = useRef(null);

  const MallNames:(any)[] = [
    {
      companyShop : [
        {shop: '레이틀리', planA: true, planB: true, planC: true, planD: true}
      ],
      openShop : [
        {shop: '11번가', planA: true, planB: true, planC: true, planD: true},
        {shop: 'G마켓', planA: true, planB: true, planC: true, planD: true},
        {shop: '옥션', planA: true, planB: true, planC: true, planD: true},
        {shop: '롯데ON', planA: true, planB: true, planC: true, planD: true},
        // {shop: '인터파크', planA: true, planB: true, planC: true, planD: true},
        {shop: '쿠팡', planA: false, planB: true, planC: true, planD: true},
        // {shop: '티몬', planA: false, planB: true, planC: true, planD: true},
        // {shop: '위메프', planA: false, planB: true, planC: true, planD: true},
        // {shop: 'Qoo10', planA: true, planB: true, planC: true, planD: true},
      ],
      synShop : [
        {shop: 'SSG', planA: false, planB: false, planC: true, planD: true},
        {shop: 'CJ 온스타일', planA: false, planB: false, planC: true, planD: false},
        {shop: '롯데홈쇼핑', planA: false, planB: false, planC: true, planD: false},
        {shop: '홈앤쇼핑', planA: false, planB: false, planC: true, planD: false},
        {shop: 'GS SHOP', planA: false, planB: false, planC: true, planD: false},
        {shop: 'SK 스토아', planA: false, planB: false, planC: true, planD: false},
        // {shop: 'AK몰', planA: false, planB: false, planC: true, planD: false},
        {shop: 'W쇼핑', planA: true, planB: true, planC: true, planD: true}
      ],
      specShop : [
        {shop: '패션플러스', planA: false, planB: false, planC: true, planD: false},
        {shop: '하프클럽', planA: false, planB: false, planC: true, planD: false},
        {shop: '공구마켓', planA: true, planB: true, planC: true, planD: false},
        {shop: '심쿵할인', planA: true, planB: true, planC: true, planD: false},
        {shop: '할인중독', planA: true, planB: true, planC: true, planD: false},
      ]
    }
  ]

  const { t } = useTranslation();

  return (
    <div className="pricingPage">
      <Helmet>
        <title>{t('pageTitle.pricing')}{t('pageTitle.common')}</title>
      </Helmet>

      <div className="pageHeader">
        <h1>{t('pageTitle.pricing')}</h1>
        <p className="description">{t('pricing.description')}</p>
      </div>

      <div className="pricingContainer">

        <div className="pricingInfo">
          <div className="container">
            <h2>{t('pricing.title')}</h2>
            <p className="description"  dangerouslySetInnerHTML={{__html:`${t('pricing.subtitle')}`}}/>

            <div className="pricingChargeBox">
              <div className="pricingDetail">
                <p>{t('pricing.title')}</p>
                <h3>{t('pricing.basicprice')}</h3>
                <span className="vat">{t('pricing.inclvat')}</span>
                <ul>
                  <li>
                    <span className="label">{t('No')}</span>
                    <p dangerouslySetInnerHTML={{__html:`${t('pricing.joinfee')}`}}/>
                  </li>
                  <li>
                    <span className="label">{t('pricing.Free')}</span>
                    <p dangerouslySetInnerHTML={{__html:`${t('pricing.freetitle')}`}}/>
                  </li>
                  <li>
                    <span className="label">{t('No')}</span>
                    <p dangerouslySetInnerHTML={{__html:`${t('pricing.midfee')}`}}/>
                  </li>
                </ul>
              </div>

              <span className="plus"><img src="/assets/images/plus@2x.png" alt="+" width="24" /></span>

              <div className="planCharge">
                <span>{t('pricing.planfee')}</span>
                <p dangerouslySetInnerHTML={{__html:`${t('pricing.plantitle')}`}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="planInfo">
          <div className="container">
            <h2>{t('pricing.planinfo')}</h2>
            <p className="description" dangerouslySetInnerHTML={{__html:`${t('pricing.infodescription')}`}}/>

            <div className="planList">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                watchSlidesProgress={true}
                initialSlide={1}
                // @ts-ignore
                ref={swiperRef}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  "@0.55": {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  }
                }}
                className="mySwiper"
              >
                <SwiperSlide key="1">
                  <div className="plan start">
                    <span className="sup">{t('pricing.starttitle')}</span>
                    <h3>{t('pricing.startplan')}</h3>

                    <div className="planData">
                      <div>
                        <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s15')}`}}/>
                        <p dangerouslySetInnerHTML={{__html:`${t('pricing.sellfee')}`}}/>
                      </div>

                      <div>
                        <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s6')}`}}/>
                        <p dangerouslySetInnerHTML={{__html:`${t('pricing.linkShop')}`}}/>
                      </div>
                    </div>

                    <p className="description"  dangerouslySetInnerHTML={{__html:`${t('pricing.startdescription')}`}}/>
                  </div>
                </SwiperSlide>

                <SwiperSlide key="2">
                  <div className="plan basic">
                    <span className="sup">{t('pricing.basictitle')}</span>
                    <h3>{t('pricing.basicplan')}</h3>

                    <div className="planData">
                      <div>
                        <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s215')}`}}/>
                        <p dangerouslySetInnerHTML={{__html:`${t('pricing.sellfee')}`}}/>
                      </div>

                      <div>
                        <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s9')}`}}/>
                        <p dangerouslySetInnerHTML={{__html:`${t('pricing.linkShop')}`}}/>
                      </div>
                    </div>

                    <p className="description" dangerouslySetInnerHTML={{__html:`${t('pricing.basicdescription')}`}}/>
                  </div>
                </SwiperSlide>

                <SwiperSlide key="3">
                  <div className="plan plus">
                    <span className="sup">{t('pricing.plustitle')}</span>
                    <h3>{t('pricing.plusplan')}</h3>

                    <div className="planData">
                      <div>
                        <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s26.5')}`}}/>
                        <p dangerouslySetInnerHTML={{__html:`${t('pricing.sellfee')}`}}/>
                      </div>

                      <div>
                        <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s21')}`}}/>
                        <p dangerouslySetInnerHTML={{__html:`${t('pricing.linkShop')}`}}/>
                      </div>
                    </div>

                    <p className="description" dangerouslySetInnerHTML={{__html:`${t('pricing.plusdescription')}`}}/>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <ul className="planList">
              <li className="plan custom">
                <div className="heading">
                  <span className="sup">{t('pricing.customtitle')}</span>
                  <h3>{t('pricing.customplan')}</h3>

                  <div className="planData">
                    <div>
                      <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s23')}`}}/>
                      <p dangerouslySetInnerHTML={{__html:`${t('pricing.sellfee')}`}}/>
                    </div>

                    <div>
                      <strong dangerouslySetInnerHTML={{__html:`${t('pricing.s12')}`}}/>
                      <p dangerouslySetInnerHTML={{__html:`${t('pricing.linkShop')}`}}/>
                    </div>
                  </div>

                  <ul>
                    <li dangerouslySetInnerHTML={{__html:`${t('pricing.md')}`}}/>
                    <li>{t('pricing.basicplus')}</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="mallInfo">
          <div className="container">
            <h2>{t('pricing.sellshop')}</h2>
            <p className="description">{t('pricing.plandetail')}</p>

            <div className="mallTable">

              <div className="header">
                <table width="100%" summary="판매 가능 쇼핑몰">
                  <tbody>
                  <tr className="headLine">
                    <th className="title">{t('pricing.useplan')}</th>
                  </tr>
                  <tr>
                    <th className="title" dangerouslySetInnerHTML={{__html:`${t('pricing.sellfee2')}`}}/>
                  </tr>
                  {MallNames.map((mall)=> (
                    mall.companyShop.map((e: any, index: number)=> (
                      <tr key={`openHead_${index}`}>
                        <th>{e.shop}</th>
                      </tr>
                    ))
                  ))}
                  <tr className="line">
                    <th className="title">{t('service.open')}</th>
                  </tr>
                  {MallNames.map((mall)=> (
                    mall.openShop.map((e: any, index: number)=> (
                      <tr key={`openHead_${index}`}>
                        <th>{e.shop}</th>
                      </tr>
                    ))
                  ))}
                  <tr className="line">
                    <th className="title">{t('service.general')}</th>
                  </tr>
                  {MallNames.map((mall)=> (
                    mall.synShop.map((e: any, index: number)=> (
                      <tr key={`synHead_${index}`}>
                        <th>{e.shop}</th>
                      </tr>
                    ))
                  ))}
                  <tr className="line">
                    <th className="title">{t('service.specialty')}</th>
                  </tr>
                  {MallNames.map((mall)=> (
                    mall.specShop.map((e: any, index: number)=> (
                      <tr key={`specHead_${index}`}>
                        <th>{e.shop}</th>
                      </tr>
                    ))
                  ))}
                  </tbody>
                </table>
              </div>

              <div className="body">
                <table width="100%" summary="플랜정보">
                  <colgroup>
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>
                  <tbody>
                  <tr className="headLine">
                    <th><span className="start">{t('pricing.startplan')}</span></th>
                    <th><span className="basic">{t('pricing.basicplan')}</span></th>
                    <th><span className="plus">{t('pricing.plusplan')}</span></th>
                    <th><span className="custom">{t('pricing.customplan')}</span></th>
                  </tr>
                  <tr>
                    <td><strong className="start">{t('pricing.15')}</strong></td>
                    <td><strong className="basic">{t('pricing.21')}</strong></td>
                    <td><strong className="plus">{t('pricing.26')}</strong></td>
                    <td><strong className="custom">{t('pricing.23')}</strong></td>
                  </tr>

                  {MallNames.map((mall)=> (
                    mall.companyShop.map((e: any, index: number)=> (
                      <tr key={`open_${index}`}>
                        <td>{(e.planA === true) ? <span className="check start">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planB === true) ? <span className="check basic">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planC === true) ? <span className="check plus">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planD === true) ? <span className="check">{t('pricing.Yes')}</span> : null}</td>
                      </tr>
                    ))
                  ))}

                  <tr className="line">
                    <td colSpan={4}>&nbsp;</td>
                  </tr>

                  {MallNames.map((mall)=> (
                    mall.openShop.map((e: any, index: number)=> (
                      <tr key={`open_${index}`}>
                        <td>{(e.planA === true) ? <span className="check start">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planB === true) ? <span className="check basic">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planC === true) ? <span className="check plus">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planD === true) ? <span className="check">{t('pricing.Yes')}</span> : null}</td>
                      </tr>
                    ))
                  ))}

                  <tr className="line">
                    <td colSpan={3}>&nbsp;</td>
                    <td className="rowMerge" rowSpan={17}><span className="mergeBox" dangerouslySetInnerHTML={{__html:`${t('pricing.md2')}`}}/></td>
                  </tr>

                  {MallNames.map((mall)=> (
                    mall.synShop.map((e: any, index: number)=> (
                      <tr key={`syn_${index}`}>
                        <td>{(e.planA === true) ? <span className="check start">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planB === true) ? <span className="check basic">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planC === true) ? <span className="check plus">{t('pricing.Yes')}</span> : null}</td>
                      </tr>
                    ))
                  ))}

                  <tr className="line">
                    <td colSpan={3}>&nbsp;</td>
                  </tr>

                  {MallNames.map((mall)=> (
                    mall.specShop.map((e: any, index: number)=> (
                      <tr key={`spec_${index}`}>
                        <td>{(e.planA === true) ? <span className="check start">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planB === true) ? <span className="check basic">{t('pricing.Yes')}</span> : null}</td>
                        <td>{(e.planC === true) ? <span className="check plus">{t('pricing.Yes')}</span> : null}</td>
                      </tr>
                    ))
                  ))}
                  </tbody>
                </table>
              </div>

            </div>
          </div>

          <div className="categoryInfo">
            <div className="headline"  dangerouslySetInnerHTML={{__html:`${t('pricing.middleheadline')}`}}/>

            {/*<Link className="link" to={'/service#category'}>판매 상품 카테고리 안내</Link>*/}
            <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                      to={'/service#category_list'} id="button-link-categories" className="linkButton">{t('pricing.sellitemcate')}</HashLink>
          </div>

        </div>

        <div className="detailFunction">
          <div className="container">
            <h2>{t('pricing.inclfunction')}</h2>
            <ul>
              <li>
                {/*<img src="/assets/images/Artboard_3@2x.png" width={80} alt="" />*/}
                <p dangerouslySetInnerHTML={{__html:`${t('pricing.detailfunction1')}`}}/>
              </li>
              <li>
                {/*<img src="/assets/images/Artboard_1@2x.png" width={80} alt="" />*/}
                <p dangerouslySetInnerHTML={{__html:`${t('pricing.detailfunction2')}`}}/>
              </li>
              <li>
                {/*<img src="/assets/images/Artboard_3@2x.png" width={80} alt="" />*/}
                <p dangerouslySetInnerHTML={{__html:`${t('pricing.detailfunction3')}`}}/>
              </li>
              {/*<li>*/}
              {/*  <img src="/assets/images/Artboard_2@2x.png" width={80} alt="" />*/}
              {/*  <p>택배 회수<br />자동화 서비스</p>*/}
              {/*</li>*/}
            </ul>
          </div>
        </div>

        <div className="getStarted">
          <h2 dangerouslySetInnerHTML={{__html:`${t('pricing.getstart')}`}}/>

          {isMobile ?
            <a
              href="https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification_mo.php"
              id="button-get-started-mo"
              className="link"
            >
              {t('pricing.startbutton')}
            </a>
            :
            <a
              href="https://admin.sellerhub.co.kr/shop/admin_provider/member/join_simplification.php"
              id="button-get-started-pc"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {t('pricing.startbutton')}
            </a>
          }
        </div>

      </div>

    </div>
  )
}
export default Pricing;