
import React from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import CompanyTotalCustomer from "../components/company/CompanyTotalCustomer"
import CompanyInovation from "../components/company/CompanyInovation";
// import CompanyGrowthFigure from "../components/company/CompanyGrowthFigure";
import CompanyHistory from "../components/company/CompanyHistory";
import CompanyNews from "../components/company/CompanyNews";
import CompanyWithPartner from "../components/company/CompanyWithPartner";
import CompanyDNA from "../components/company/CompanyDNA";
import CompanyCareer from "../components/company/CompanyCareer";
import Wavify from "../components/company/Wavify";

const Intro = () => {
  const {t} = useTranslation()

  return (
    <div className="CompanyIntro">
      <Helmet>
        <title>{t('pageTitle.service')} | 회사 소개</title>
      </Helmet>
      <div>
        <CompanyTotalCustomer/>
        <CompanyInovation/>
        {/*<CompanyGrowthFigure/>*/}
        <Wavify/>
        <CompanyHistory/>
        <CompanyNews/>
        <CompanyWithPartner/>
        <CompanyDNA/>
        <CompanyCareer/>
      </div>
    </div>
  );
}
export default Intro;