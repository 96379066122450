import React, { useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import noImage from '../assets/images/noneImage.png';
import { useTranslation } from 'react-i18next';
import "../assets/events.scss"
import Banner from "../components/banner/Banner";
import "moment/locale/ko";
import moment from 'moment';


const Events = () => {
  const [eventList, setEventList] = useState([]);//API Data
  const [loading, setLoading] = useState(true);//for Skeleton control
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listSize, setListSize] = useState(6);//Per page list count
  const [moreShow, setMoreShow] = useState(false);//More list state
  const [eventType, setEventType] = useState <any>({
    page: 1,
    size: 9,
    type: "",
    sts :""
  });
  const { t } = useTranslation();
  // function newsSkeleton({ children }: PropsWithChildren<unknown>) {
  //   return (
  //     <div
  //       style={{
  //         display: 'block',
  //         lineHeight: 2,
  //         padding: '1rem',
  //         borderRadius: '0.25rem',
  //         marginBottom: '1rem',
  //         width: "100%",
  //       }}
  //     >
  //
  //       {children}
  //     </div>
  //   )
  // }

  const handleImgError = (e: any) => {
    e.target.src = noImage;
  };//no image


  const getEventList = async (page: number,size:number, type:string,sts:string) => {
    try {
      // setLoading(true)
      // init value
      const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/event/?page=${page}&size=${size}&type=${type}&status=${sts}&isPriority=N`);
      if (status === 200) {
        setTimeout(() => {
          setLoading(false)
          // eventType.sts === 'expired' ? setEventList(data.data.filter((value: any) => value.status !== 'expired')) : setEventList(data.data)
          setEventList(data.data)
          //page값 변경
          const { current_page, last_page } = data
          //have a next page show more button
          current_page >= last_page ? setMoreShow(false) : setMoreShow(true)

          //event List 업데이트
          current_page === 1 ? setEventList(data.data) : setEventList(eventList.concat(data.data))
        }, 400);
      }

    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.response.data;
      setLoading(false);
    }
  };

  const getChangeType = (type: any) => {
    setEventType((prev:any) => ({...prev, type:type, page:1, sts:""}))
  }

  const addPage = () => {
    setEventType((prev: any) => ({...prev, page:eventType.page+1}))
  }

  const changeStatus = (e: any) => {
    setEventType((prev: any) => ({...prev, sts:e.target.value, page:1}))
    //console.log(" eventType: " ,eventType)
  }

  const getDay = (e: any) => {
    const today = moment(new Date())
    const local2 = moment(e);
    const result = local2.diff(today,'days')

    return result <= 0 ? "오늘 종료" : result+"일 남음"
  }

  useEffect(()=> {
    getEventList(eventType.page,eventType.size,eventType.type,eventType.sts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSize,eventType]);

  return (
    <div className="eventsPage">
      <Helmet>
        <title>{t('pageTitle.news')}{t('pageTitle.common')}</title>
      </Helmet>

      <div className="bannerContainer">
        <Banner/>
      </div>

      <div className="subPage">
        <div className="buttonList_event">
          <button className={eventType.type === "" ? "event_type_button active" : "event_type_button"}
                  onClick={() => getChangeType("")}>{t('event.total')}
          </button>
          <button className={eventType.type === "info" ? "event_type_button active" : "event_type_button"}
                  onClick={() => getChangeType("info")}>{t('event.serviceNews')}
          </button>
          <button className={eventType.type === "event" ? "event_type_button active" : "event_type_button"}
                  onClick={() => getChangeType("event")}>{t('event.event')}
          </button>
        </div>

        {eventType.type === "event" &&
          <div className="event_dropdown">
            <select className="dropdown" onChange={(e) => {
              changeStatus(e)
            }}>
              <option className="dropdown_value" value="">{t('event.listTotal')}</option>
              {/*<option className="dropdown_value" value="always">상시</option>*/}
              <option className="dropdown_value" value="alwaysAndProceeding">{t('event.Processing')}</option>
              <option className="dropdown_value" value="expired">{t('event.end')}</option>
            </select>
          </div>
        }

        {loading ?
          <>
            <div className={"skele"}>
              <Skeleton style={{marginBottom: '60px', marginRight: '60px'}} width={320} height={320} count={3}/>
              <Skeleton style={{marginBottom: '60px', marginRight: '60px'}} width={320} height={320} count={3}/>
              <Skeleton style={{marginBottom: '60px', marginRight: '60px'}} width={320} height={320} count={3}/>
            </div>
          </>
          :
          <>
            {!loading &&
            (eventList.length !== 0) ? (
                <ul className="eventContainer">
                  {eventList.map((events: any) => (
                    <li className="eventItem" key={events.provider_bbs_sno}>
                      <Link className="eventItemLink" to={`${events.provider_bbs_sno}`}>
                        <img className="eventImage" src={`${events.tImg}`} alt={events.subject} onError={handleImgError}/>
                        {/*{events.edate &&*/}
                        {/*  events.type === "event" && events.status === "proceeding" ? <div className="eventEndDate"> <Moment format={'DD일 남음'} >{events.edate}</Moment></div> : <div className="eventEndDate">상시</div>*/}
                        {/*}*/}
                        {/*{events.status === "expired" ? <span className="endEvent">종료된 이벤트</span> : ""}*/}
                        {events.type === "event" && events.status === "proceeding" ?
                          <div className="eventEndDate">{getDay(events.edate)}</div> :
                          /*  { events.type === "event" && events.status === "proceeding" ? <div className="eventEndDate"> <Moment format={"DDD"}>{events.edate}</Moment></div> :*/
                          events.type === "event" && events.status === "always" ? <div className="eventAlwaysDate">{t('event.always')}</div> :
                            events.type === "event" && events.status === "expired" ?
                              <span className="endEvent">{t('event.endEvent')}</span> : ""
                        }
                        {/*{events.status === "expired" ? <span className="endEvent">종료된 이벤트</span> : ""}*/}
                      </Link>
                    </li>
                  ))}
                </ul>
              )

              : (
                <div>{t('event.none')}</div>
              )}
          </>}
        {moreShow &&
          <div className="add_button_section">
            <button className="add_button" onClick={() => {
              addPage()
            }}>{t('button.add')}
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default Events;
