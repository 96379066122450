import React from 'react';
import CountUp from "react-countup";
import {useTranslation} from "react-i18next";


const CompanyTotalCustomer = () => {
  const { t } = useTranslation();

  return (
    <div className="intro_Container">
      <div className="subPage">
        <div className="intro_title">
          <div className="intro_big_title" dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.bigTitle')}`}}/>
          <div className="intro_little_title" dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.littleTitle')}`}}/>
        </div>
        <div>
          <div>
            <div className="intro_EarnCount">
              <div className="intro_EarnContainer">
                <span className="intro_earn_number"><CountUp end={366} duration={1} suffix="만+"/>
                </span>
                <span className="intro_earn_text" dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.numberOfProducts')}`}}/>
              </div>
              <div className="intro_EarnContainer">
                <span className="intro_earn_number"><CountUp end={3700} duration={1} separator={","} decimal="," suffix="억+"/></span>
                <span className="intro_earn_text" dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.turnOver')}`}}/>
              </div>
              <div className="intro_EarnContainer">
                <span className="intro_earn_number"><CountUp end={249} duration={1} decimal="," suffix="억원"/></span>
                <span className="intro_earn_text" dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.investmentAmount')}`}}/>
              </div>
              <div className="intro_EarnContainer">
                <span className="intro_earn_number"><CountUp end={39000} duration={1} separator={","} decimal="," suffix="+"/></span>
                <span className="intro_earn_text" dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.totalCustomer')}`}}/>
              </div>
            </div>
            <div className="intro_date_end"><span dangerouslySetInnerHTML={{__html:`${t('CompanyTotalCustomer.dateEnd')}`}}/></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyTotalCustomer;