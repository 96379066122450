import React from 'react';
import {useTranslation} from "react-i18next";

const CompanyCareer = () => {
  const {t} = useTranslation()

  return (
    <div className="intro8_Container">
      <div className="intro8_Container_subPage">
        <div className="intro8_title">
          <span className="CareerTitle" dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.Career.title')}`}} />
        </div>
        <div className="careerButton" id="button-link-career">
          <a className="companyCareerGo" href="https://career.sellerhub.co.kr/" target="_blank" rel="noreferrer" >
            <span>{t('CompanyIntro.Career.button')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompanyCareer;
