import React from 'react';
import Wave from "react-wavify"
const Wavify = () => {
  return (
    <div className="Wave_background">
      <Wave fill='#3254ff'
            paused={false}
            options={{
              height: 30,
              amplitude: 30,
              speed: 0.3,
              points: 4
            }}
      />
    </div>
  );
};

export default Wavify;
