import axios from "axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


const CompanyNews = () => {
  const { t } = useTranslation();

  const [newsList, setNewsList] = useState([]);
  const [pageSize, setPageSize] = useState(3);
  const [addOn, setAddOn] = useState(true);
  const getNews = async () => {
    try {
      const {data, status} = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/news?page=1&size=5`);
      // const {data, status} = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/news`);
      if (status === 200) {
        setNewsList(data.data);
      }

    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.response.data;
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    getNews()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    pageSize === 5? setAddOn(false) : null
  }, [pageSize])

  return (
    <div className="intro5_Container">
      <div className="intro5_Container_subPage">
        <div className="intro5_Title">{t("CompanyNews.title")}</div>
        <div className="intro5_News_Container">
          {newsList &&
            <div className="intro5_News_List">
              {newsList.map((news: any, i: number) => (
                  <div className="intro5_NewsItem_Container" key={news.parent}>
                    <div className="intro5_NewsItem_Left">
                      <div className="intro5_News_Subject">{news.subject}</div>
                      <p className="intro5_News_Date">{news.regdt.slice(0,10)}</p>
                      <div className="intro5_News_Subtitle" dangerouslySetInnerHTML={{__html:news.contents}}/>
                      <a href={`${news.option2}`} target="_blank" rel="noreferrer">{t("CompanyNews.origin_news")} &gt;</a>
                    </div>
                    <div className="intro5_NewsItem_right">
                      <a href={`${news.option2}`} target="_blank" rel="noreferrer"><img className="intro5_News_image" src={`${news.thumbnail_image}`} alt={news.subject}/></a>
                    </div>
                  </div>
                )
              ).slice(0,pageSize)}
              {addOn &&
                <button className="intro5_News_List_button" disabled={pageSize===5} onClick={() => setPageSize(pageSize+2)}>{t("button.add")}</button>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}
export default CompanyNews;