import {Swiper, SwiperSlide} from "swiper/react";
import axios from "axios";
import {useEffect, useState} from "react";
import SwiperCore, {Autoplay, EffectFade, Navigation} from "swiper";

const TopBanner = () => {
  SwiperCore.use([Autoplay]);
  const [bannerInfo, setBannerInfo ] = useState<any>([]);

  const getBannerInfo = async () => {
    try {
      // const {data, status} = await axios.get('https://hhbconn.sellerhub.co.kr/api/v2/homepage/banner');
      const {data, status} = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/banner`);
      // const {data, status} = await axios.get(`https://sellerhub.co.kr/homepage/banner`);

      if (status === 200) {
        setBannerInfo(data)
      }

    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.response.data;
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    getBannerInfo()
  }, []);


  return (
    <div className="topBannerContainer">
      <div className="topBanner">
        {bannerInfo &&
          <Swiper
            initialSlide={1}
            className="mySwiper"
            modules={[Navigation,EffectFade]}
            effect={"fade"}
            slidesPerView={1}
            loop={true}
            navigation
            autoplay={{delay: 5000}}
          >
            <>
              {bannerInfo.map((v: any, i: number) => {
                return (
                  <SwiperSlide key={i} >

                    <a className="linkTag" id={`topBanner_${v.provider_bbs_sno}`} style={{backgroundImage: `url(${v.image})`}} href={v.link} target="_blank" rel="noreferrer">
                      <div className="titleContainer">
                        <div className="subjectTitle">{v.subject}</div>
                        <div className="subtitle">{v.subtitle}</div>
                      </div>
                    </a>
                  </SwiperSlide>
                )
              })}
            </>
          </Swiper>
        }
      </div>
    </div>
  );
}
export default TopBanner;