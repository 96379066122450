import React from 'react';
import {useTranslation} from "react-i18next";

const CompanyWithPartner = () => {
  const {t} = useTranslation();

  const partners = [1,2,3,4,6,7];
  const partners2 = [8,9,10,11,12,13];
  const partners3 = [14,15,16,17,18,19];
  // const isMobile = useMediaQuery({
  //   query: '(max-width: 480px)'
  // });

  return (
    <div className="intro6_Container">
      <div className="intro6_Container_subPage">
        <div className="intro6">
          <h1 className="intro6_title">{t("CompanyWithPartner.title")}</h1>
          <ul className="intro6_partnerList">
            {partners.map((names: any, num: number) => (
                <li key={num}><img  key={num} src={`/assets/images/partner_logo-${names}@2x.png`} alt="" /></li>
            ))}
          </ul>
          <ul className="intro6_partnerList">
            {partners2.map((names: any, num: number) => (
              <li key={num}><img key={num} src={`/assets/images/partner_logo-${names}@2x.png`} alt="" /></li>
            ))}
          </ul>
          <ul className="intro6_partnerList">
            {partners3.map((names: any, num: number) => (
              <li key={num}><img key={num} src={`/assets/images/partner_logo-${names}@2x.png`} alt="" /></li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyWithPartner;
