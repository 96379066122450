import React, {useEffect} from 'react';
import {useRoutes, useLocation, useNavigate} from 'react-router-dom';
import {
  EventDetail, Events, Help, Home,
  //News, NewsDetail,
  Notfound, Pricing,
  Service, Tutorial,
  Terms, Privacy
} from './pages';
import Layout from "./Layout";
import CompanyIntro from "./pages/CompanyIntro";
import CS from "./pages/CS";


const AppRoutes = () => {

  const url = useLocation()
  const navigate = useNavigate()
  useEffect(() => {

    if(url.pathname.toLowerCase() === "/faq.php"){
      navigate('/helpcenter')
    }
  },[url,navigate])

  return useRoutes([
    {
      path: '/',
      element: <Layout/>,
      children: [
        {path: '/', element: <Home/>},
        {path: 'company', element: <CompanyIntro/>},
        {path: 'service', element: <Service/>},
        // // { path: 'about', element: <About /> },
        // {path: 'news', element: <News/>},
        // {path: 'news/:newsId', element: <NewsDetail/>},
        {path: 'events', element: <Events/>},
        {path: 'events/:eventId', element: <EventDetail/>},
        {path: 'faq', element: <Help/>},
        {path: 'helpcenter', element: <CS/>},
        {path: 'tutorial', element: <Tutorial/>},
        {path: 'pricing', element: <Pricing/>}
      ]
    },
    {path: '/terms', element: <Terms />},
    {path: '/privacy', element: <Privacy />},
    {path: '*', element: <Notfound/>}
  ]);
}
export default AppRoutes;