import React, {useState, useEffect, PropsWithChildren} from 'react';
import FaqItems from "./FaqItems";
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Accordion, AccordionItem} from "react-accessible-accordion";
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
  const { t } = useTranslation()
  const [faqList, setFaqList] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const dataList: any = []
  const [faqSize, setFaqSize] = useState<number>(5)
  const [add, setAdd] = useState<boolean>(true)
  const [active, setActive] = useState<any>("all");
  const [isExpandAll, setExpandAll] = useState(false);

  const [typeALL, setTypeALL] = useState<any[]>([])
  const [typeL, setTypeL] = useState<any[]>([])
  const [typeR, setTypeR] = useState<any[]>([])
  const [typeF, setTypeF] = useState<any[]>([])
  const [typeA, setTypeA] = useState<any[]>([])
  const [typeTOP, setTypeTOP] = useState<any[]>([])

  function newsSkeleton({ children }: PropsWithChildren<unknown>) {
    return (
      <div
        style={{
          display: 'block',
          lineHeight: 2,
          padding: '1rem',
          marginBottom: '1rem',
          width: "100%",
        }}
      >
        {children}
      </div>
    )
  }

  const handleOnChange = () => {
    // @ts-ignore
    setExpandAll(undefined);
  };

  const getFAQ = async (e: null | any) => {
    try {
      const { data, status } = await axios.get(`${process.env.REACT_APP_PROXY}/homepage/faq?best=${0}&page=${0}&size=${30}&category=${e}`)
      if (status === 200) {
        setTimeout(() => {
          setLoading(false)
          data.data.map((v: any) => {
            return dataList.push(v)
          })
          setFaqList(dataList)
          setTypeALL(dataList)
          dataList.forEach((value: any, i: number) => {

            if (value.best === "y") {
              setTypeTOP((prevState => [...prevState, value]))
            }
            if (value.category === "l") {
              setTypeL((prevState => [...prevState, value]))
            } else if (value.category === "r") {
              setTypeR((prevState => [...prevState, value]))
            } else if (value.category === "f") {
              setTypeF((prevState => [...prevState, value]))
            } else if (value.category === "a") {
              setTypeA((prevState => [...prevState, value]))
            }
            // value.category === "l" ? setTypeL((prevState => [...prevState, value]))
            // value.category === "r" ? setTypeR((prevState => [...prevState, value]))
            // value.category === "f" ? setTypeF((prevState => [...prevState, value]))
            // value.category === "a" ? setTypeA((prevState => [...prevState, value])) null
            // return console.log("in array dataList : ",value);
          })
        }, 500)
      }
    }catch (error) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.response.data;
      setLoading(false);
    }
  }

  const changeList = (e: any) => {
    // @ts-ignore
    setExpandAll(undefined);
    setActive(e);
    setFaqSize(5);
    return e === "all" ? setFaqList(typeALL) :
           e === "t" ? setFaqList(typeTOP) :
           e === "l" ? setFaqList(typeL) :
           e === "r" ? setFaqList(typeR) :
           e === "f" ? setFaqList(typeF) :
           e === "a" ? setFaqList(typeA) : null
  }

  useEffect(() => {
    getFAQ("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect( () => {
    faqList.length <= faqSize ? setAdd(false): setAdd(true)
  }, [faqList.length, faqSize])

  return (
    <div className="faqContents subPage">
      <Helmet>
        <title>{t('pageTitle.help')}{t('pageTitle.common')}</title>
      </Helmet>
      <div className="buttonWrap">
        <ul className="buttonList">
          <li>
            <button className={active === "all" ? "faq_button active" :"faq_button"} onClick={() => {changeList("all")}}>{t('faqs.total')}</button>
          </li>
          <li><button className={active === "t" ? "faq_button active" :"faq_button"} onClick={() => {changeList("t")}}>{t('faqs.top')}</button>
            </li>
          <li><button className={active === "l" ? "faq_button active" :"faq_button"} onClick={() => {changeList("l")}}>{t('faqs.store')}</button>
            </li>
          <li><button className={active === "r" ? "faq_button active" :"faq_button"} onClick={() => {changeList("r")}}>{t('faqs.operation')}</button>
            </li>
          <li><button className={active === "f" ? "faq_button active" :"faq_button"} onClick={() => {changeList("f")}}>{t('faqs.pricing')}</button>
            </li>
          <li><button className={active === "a" ? "faq_button active" :"faq_button"} onClick={() => {changeList("a")}}>{t('faqs.addition')}</button></li>
        </ul>
      </div>
      {loading ?
        <>
          <Skeleton wrapper={newsSkeleton} width="70%" height={30} count={1}/>
          <Skeleton wrapper={newsSkeleton} width="30%" height={30} count={1}/>
          <Skeleton wrapper={newsSkeleton} width="20%" height={30} count={1}/>
          <Skeleton wrapper={newsSkeleton} width="60%" height={30} count={1}/>
          <Skeleton wrapper={newsSkeleton} width="15%" height={30} count={1}/>
          <Skeleton wrapper={newsSkeleton} width="75%" height={30} count={1}/>
        </>
        :
        <Accordion className="accordion_list" allowZeroExpanded={true} onChange={handleOnChange}>
          {faqList &&
            faqList.map((e, i) =>
              <AccordionItem className="stack" key={e.provider_bbs_sno} dangerouslySetExpanded={isExpandAll}>
                <FaqItems contents={e.contents} subject={e.subject}/>
              </AccordionItem>
            ).slice(0,faqSize)
          }
        </Accordion>
      }
      {add &&
        <div className="add_button_section">
          <div className="add_button_wrap"><button className="add_button" onClick={() => setFaqSize((prev) => prev+5)}>{t('button.add')}</button></div>
        </div>
      }
    </div>

  );
};
export default Faq;


